import React from "react";

const Progress = ({ step, stepCount, children }) => {
  return (
    <>
      <div>
        Step: {step} / {stepCount}
      </div>
      {children}
    </>
  );
};

export default Progress;
